import React from "react"
import Layout from "../../components/Layout"
import RightColumn from "../../components/RightColumn"

export default class ChemistryTestingPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo02.jpg" sidebarImageAlt="Chemistry Testing" metaTitle="Chemistry Testing">
          
          <div id="mainContent">
    <div class="header-one">Chemistry Testing</div>
<p><strong>WBA Analytical Laboratories</strong><strong>&trade;</strong> provide general proximate analysis, analytical chemistry, pesticide residue analysis, nutritional analysis, and complete nutrition facts panels using analytical data or theoretical data. </p>
<p>Technologies utilized include:</p>
<ul>
<li>Gas Chromatography (ECD, FID, GC-MS)</li>
<li>Liquid Chromatography (HPLC-RI, UV, Coulochem, ELSD, and HPLC-MS/MS</li>
<li>Inductively Coupled Plasma Spectroscopy (ICP)</li>
<li>Ion Chromatography</li>
<li>Nitrogen by Combustion</li>
<li>Standard wet chemistry methodology </li></ul>
<p>Additional test methods not listed below may be available.&nbsp;&nbsp;<a href="/contact-us">Contact us</a> for further information, requests, or customized services.</p>
</div>

        <RightColumn />

        <br class="clearfloat" />

        <h2 class="tableTitle">Alphabetical List of Routine Chemistry Testing</h2>
  
<table class="testTable">

    <tr class="darkGreyBack">
        <td class="column-1">
            Analysis
        </td>
        <td class="column-2">
            Analysis Methodology
        </td>
        <td class="column-3">
            ISO 17025 Accredited
        </td>
        <td class="column-4">
            Springdale
        </td>
        <td class="column-5">
            Wilkesboro
        </td>
    </tr>

    
            <tr>            
                <td class="column-1">              
                    Aflatoxin (ELISA)
                </td>
                <td class="column-2">              
                    AOAC 989.06 and 990.32
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Alimet
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    AME
                </td>
                <td class="column-2">              
                    Apparent Metabolizeable Energy Calculation
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Ammonia
                </td>
                <td class="column-2">              
                    Metrohm IC App Note AN-C-14
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Antibiotics - Bacitracin (Cyclic Peptide)
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Chloramphenicol
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Antibiotics - Nitrofurans
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Bambermycins
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Antibiotics - Lincomycin
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Macrolides
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Antibiotics - Penicillin G
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Quinolones
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Antibiotics - Sulfonamides
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics (Tetracyclines)
                </td>
                <td class="column-2">              
                    AOAC method 995.09 (Russian Export)
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Ash
                </td>
                <td class="column-2">              
                    AOAC 942.05; 923.03
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Beta Agonists
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Biogenic Amines
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Bisphenol A
                </td>
                <td class="column-2">              
                    LC-MS-MS
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Butyric Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Carbohydrates
                </td>
                <td class="column-2">              
                    Calculation (PMFA)
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Chloride
                </td>
                <td class="column-2">              
                    AOAC 969.10
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Cholesterol
                </td>
                <td class="column-2">              
                    JAOAC 69 (5) pg 844-46, 1986
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Chondroitin
                </td>
                <td class="column-2">              
                    WBA Internal Method
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Coccidiostats
                </td>
                <td class="column-2">              
                    Internal LC-MS/MS
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Fat by Acid Hydrolysis
                </td>
                <td class="column-2">              
                    AOAC 922.06
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Fat by Rapid Soxhlet
                </td>
                <td class="column-2">              
                    AOAC 920.39
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Fat by Soxhlet
                </td>
                <td class="column-2">              
                    USDA Method &quot;FAT1&quot;
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Fatty Acid Profile
                </td>
                <td class="column-2">              
                    AOCS Ce 2-66
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Fiber, Crude
                </td>
                <td class="column-2">              
                    AOAC 962.09 part E, AOCS Ba 6a-05
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Fiber, Dietary
                </td>
                <td class="column-2">              
                    AOAC 991.42
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Free Fatty Acids
                </td>
                <td class="column-2">              
                    AOCS Ca 5a-40 Modified
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Glucosamine
                </td>
                <td class="column-2">              
                    Internal Method
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Glycerol Hydraulic Fluid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Hippuric Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Ink Dye in Tissue
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Insoluble
                </td>
                <td class="column-2">              
                    AOCS Ca 2c-25
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Isovaleric Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Kilocalories
                </td>
                <td class="column-2">              
                    Calculation (PFC)
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Lactate/Acetate
                </td>
                <td class="column-2">              
                    AOAC 973.31 Prep, Metrohm IC App Notes
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Minerals (ICP)
                </td>
                <td class="column-2">              
                    AOAC 968.08; 990.08
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Moisture, grain/feeds
                </td>
                <td class="column-2">              
                    AOAC 930.15
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Moisture, meat/food
                </td>
                <td class="column-2">              
                    USDA Method &quot;MOI&quot;
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Moisture, Volatiles
                </td>
                <td class="column-2">              
                    AOCS Ca-2c-25
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Mycotoxins in Feeds - Individual or Multi-analysis
                </td>
                <td class="column-2">              
                    LC-MS/MS Gerstal TechNote ITN-243
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Mycotoxins in Urine - Individual or Multi-analysis
                </td>
                <td class="column-2">              
                    LC-MS/MS Gerstal TechNote ITN-243
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Nitrate
                </td>
                <td class="column-2">              
                    Metrohm IC App Note AN-S-20
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Nitrite
                </td>
                <td class="column-2">              
                    Metrohm IC App Note AN-S-20
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Oxidative Stability Index
                </td>
                <td class="column-2">              
                    AOCS Cd 12b-92
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    PCP/PCA
                </td>
                <td class="column-2">              
                    Internal Method
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Percent Bone
                </td>
                <td class="column-2">              
                    AOAC 983.19 Modified for ICP)
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Peroxides - Grain/Feed
                </td>
                <td class="column-2">              
                    AOCA Ca 8b-90
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Peroxides - Oils
                </td>
                <td class="column-2">              
                    AOCA Ca 8b-90
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Pesticides
                </td>
                <td class="column-2">              
                    FSIS Determinative Method &quot;CHC2&quot; July 1991
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Pesticides-RusExport
                </td>
                <td class="column-2">              
                    FSIS Determinative Method &quot;CHC2&quot; July 1991
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    pH
                </td>
                <td class="column-2">              
                    AOAC 981.12, 943.02
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Protein, Crude
                </td>
                <td class="column-2">              
                    AOAC 992.15; 990.03
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Quaternary Ammonia
                </td>
                <td class="column-2">              
                    Journal of Pharmaceutical &amp; Biomedical Analysis; 34 (2004) 909-620
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Rhodimet
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Salt
                </td>
                <td class="column-2">              
                    USDA Method &quot;SLT&quot;
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Scoville Heat Units
                </td>
                <td class="column-2">              
                    AOAC 995.03 Modified
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Sugars
                </td>
                <td class="column-2">              
                    AOAC 982.14
                </td>
                <td class="column-3">                                
                    yes                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Taurine
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    TBA (Rancidity in Meat)
                </td>
                <td class="column-2">              
                    Journal of the American Oil Chemists' Society, 37: #1, 1960
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Therapeutic
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Trace Metals in Tallow
                </td>
                <td class="column-2">              
                    Internal (Ba,Ca,Cr,Cu,Fe,K,Li,Mg,Mn,Na,Ni,P, Pb,Si,Sr, &amp; Zn)
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Antibiotics - Trimethoprim
                </td>
                <td class="column-2">              
                    Mod. AOAC 995.09
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin A
                </td>
                <td class="column-2">              
                    USDA &quot;VTA&quot; (Modified)
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin B1 - Thiamine
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin B2 - Riboflavin
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin B3 - Nicotinamide
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin B3 - Nicotinic Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin B5 - Pantothenic Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin B6 - Pyridoxine
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin B6 - Pyridoxal
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin B8 - Biotin
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin B9 - Folic Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin B12 - Cyanocobalamin
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vitamin C
                </td>
                <td class="column-2">              
                    Internal Method
                </td>
                <td class="column-3">                                
                    yes
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Vitamin C - Ascorbic Acid
                </td>
                <td class="column-2">              
                    LC-MS/MS Internal
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
            <tr class="lightGreyBack">            
                <td class="column-1">              
                    Vomitoxin
                </td>
                <td class="column-2">              
                    ELISA
                </td>
                <td class="column-3">                                
                    no
                </td>
                <td class="column-4">              
                    <span class='fontMedium'>--</span>
                </td>
                <td class="column-5">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
            </tr>
        
            <tr>            
                <td class="column-1">              
                    Water Activity Ratio
                </td>
                <td class="column-2">              
                    Decagon Aqualab
                </td>
                <td class="column-3">                                
                    no                   
                </td>
                <td class="column-4">              
                    <img src='/images/checkmark.png' alt='checkmark' />
                </td>
                <td class="column-5">              
                    <span class='fontMedium'>--</span>
                </td>
            </tr>
        
</table>

      </Layout>
    )
  }
}
